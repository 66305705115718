<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <SearchBar
          ref="searchBar"
          @search="onSearch($event)"
          searchLabel="Search by course name..."
        />
      </v-col>
      <v-col align="right">
        <AttachCourses @refresh="getItems()" />
      </v-col>
      <v-col class="shrink">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-blur
              class="msaBlue white--text"
              v-on="on"
              @click="exportItems()"
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </template>
          <span>Export</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-data-table
            v-model="selectedCourses"
            show-select
            :headers="headers"
            :items="items"
            :server-items-length="itemsCount"
            :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
            :options.sync="options"
            @update:options="updateUrl()"
            checkbox-color="msaBlue"
            class="colored-table-check-box"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-blur
                    color="red"
                    text
                    small
                    v-on="on"
                    @click="detach(item)"
                  >
                    <v-icon> mdi-tag-off-outline </v-icon>
                  </v-btn>
                </template>
                <span>Detach</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <span class="d-flex justify-center">
      <GroupedCoursesMassManage
        v-if="selectedCourses.length > 0"
        :courses="selectedCourses"
        @onUnselect="unselectCourse($event.id)"
        @unselectAll="unselectAll()"
        @detachAll="detachAll()"
      />
    </span>
  </v-container>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue';
import AttachCourses from '@/components/WALabels/AttachCourses.vue';
import GroupedCoursesMassManage from '@/components/CompanyGroupedMassManagers/GroupedCoursesMassManage.vue';

export default {
  name: 'CompanyGroupedCoursesPage',
  components: {
    SearchBar,
    AttachCourses,
    GroupedCoursesMassManage,
  },
  watch: {
    selectedCourses: {
      deep: true,
      handler(val) {
        this.$store.commit('updateGroupedCoursesSelected', val);
      },
    },
  },
  data() {
    return {
      search: '',
      items: [],
      itemsCount: 0,
      selectedCourses: [],
      options: {
        sortBy: ['name'],
        sortDesc: [false],
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
      },
      headers: [
        {
          text: 'Name',
          value: 'name',
          align: 'start',
          class: 'lightGrey',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          class: 'lightGrey',
          sortable: false,
          width: '60px',
        },
      ],
    };
  },
  methods: {
    unselectCourse(id) {
      this.selectedCourses = this.selectedCourses.filter(
        (course) => course.id != id,
      );
    },
    unselectAll() {
      this.selectedCourses = [];
    },

    onSearch(event) {
      this.search = event;
      this.options.page = 1;
      this.updateUrl();
    },
    updateUrl() {
      const params = {};
      if (this.options.page != this.$constants.PAGINATION.DEFAULT_PAGE) {
        params.page = this.options.page;
      }
      if (
        this.options.itemsPerPage != this.$constants.PAGINATION.DEFAULT_PERPAGE
      ) {
        params.perPage = this.options.itemsPerPage;
      }
      if (this.search != '') {
        params.search = this.search;
      }
      if (this.options.sortDesc[0]) {
        params.sortDesc = this.options.sortDesc[0];
      }

      let changed = false;
      if (Object.keys(params).length != Object.keys(this.$route.query).length) {
        changed = true;
      } else {
        for (const key in params) {
          if (params[key] != this.$route.query[key]) {
            changed = true;
          }
        }
      }

      if (changed) {
        this.$router.replace({
          name: this.$route.name,
          query: params,
        });
      }
    },
    getRouteQueries() {
      const page = this.$route.query.page;
      this.options.page =
        page == undefined ||
        isNaN(page) ||
        parseInt(page) < this.$constants.PAGINATION.DEFAULT_PAGE
          ? this.$constants.PAGINATION.DEFAULT_PAGE
          : parseInt(page);

      const perPage = this.$route.query.perPage;
      this.options.itemsPerPage =
        perPage == undefined ||
        isNaN(perPage) ||
        !this.$constants.PAGINATION.DEFAULT_FOOTER_PROPS[
          'items-per-page-options'
        ].includes(parseInt(perPage))
          ? this.$constants.PAGINATION.DEFAULT_PERPAGE
          : parseInt(perPage);

      const search = this.$route.query.search;
      this.search =
        search == undefined || search.trim().length == 0 ? '' : search.trim();

      const sortDesc = this.$route.query.sortDesc;
      this.options.sortDesc[0] =
        sortDesc == undefined || !sortDesc ? false : true;

      this.selectedCourses = [...this.$store.getters.groupedCoursesSelected];
    },
    getItems() {
      const params = {
        search: this.search ?? '',
        options: this.options,
        loaderText: 'Loading...',
        id: parseInt(this.$route.params.id),
      };

      return this.$axios
        .post('get-attached-courses-for-label?format=json', params)
        .then((response) => {
          this.items = response.data.items;
          this.itemsCount = response.data.count;
        });
    },
    detach(item) {
      this.$root.showMessage(
        'Confirm',
        'Are you sure you want to detach this course?',
        () => confirm(),
        () => false,
        'Confirm',
        'Cancel',
      );
      const confirm = () => {
        this.selectedCourses = this.selectedCourses.filter(
          (course) => course.id != item.id,
        );

        const params = {
          labelId: this.$store.getters.selectedLabel.id,
          idsToRemove: [item.id],
          loaderText: 'Detaching...',
          disableAutoError: true,
        };
        this.$axios
          .post('detach-courses-from-label?format=json', params)
          .then(() => this.getItems())
          .catch((error) => error);
      };
    },
    detachAll() {
      const idsToRemove = this.selectedCourses.map((course) => course.id);

      const params = {
        labelId: this.$store.getters.selectedLabel.id,
        idsToRemove: idsToRemove,
        loaderText: 'Detaching...',
      };
      this.$axios
        .post('detach-courses-from-label?format=json', params)
        .then(() => {
          this.selectedCourses = [];
          this.getItems();
        });
    },
    exportItems() {
      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };
      const params = {
        search: this.search ?? '',
        options: this.options,
        loaderText: 'loading...',
        id: this.$store.getters.selectedLabel.id,
      };

      this.$axios
        .post('export-attached-courses-for-label?format=json', params, options)
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Attached Courses Export.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => error);
    },
  },
  mounted() {
    this.getItems().catch((error) => {
      if (error.response && error.response.data) {
        this.$root.showMessage(
          '',
          error.response.data,
          () => this.$router.replace({ name: 'CompanyGroups' }),
          null,
          'ok',
          null,
          true,
        );
      } else {
        return error;
      }
    });
    if (this.search != '') {
      this.$refs.searchBar.setSearch(this.search);
    }
  },
  created() {
    this.getRouteQueries();
  },
  beforeRouteLeave(to, from, next) {
    // get rid of selection if user nav to another view
    if (to.name != from.name) {
      this.$store.commit('updateGroupedCoursesSelected', []);
    }
    next();
  },
};
</script>

<style></style>
