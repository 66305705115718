<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            class="msaBlue white--text"
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            @click="openDialog()"
          >
            <v-icon> mdi-tag-plus-outline </v-icon>
          </v-btn>
        </template>
        <span>Attach Courses</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-footer
        padless
        tile
        class="msaBlue white--text"
        style="position: sticky; top: 0; z-index: 999"
      >
        <v-container fluid>
          <v-row>
            <v-col class="text-h6"> Attach Courses </v-col>
          </v-row>
        </v-container>
      </v-footer>
      <v-card-text class="mt-4">
        <v-row>
          <v-col>
            <SearchBar
              :key="dialog"
              searchLabel="Search by course name..."
              @search="onSearch"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-expansion-panels v-if="items.length > 0" v-model="expanded">
              <v-expansion-panel
                v-for="(group, index) in items"
                :key="index"
                class="mb-4"
              >
                <v-expansion-panel-header
                  hide-actions
                  v-slot="{ open }"
                  :style="headerStyles(index)"
                  :class="headerClass(index)"
                >
                  <v-row align="center">
                    <v-col class="shrink">
                      <v-icon v-if="open" color="white">mdi-minus-thick</v-icon>
                      <v-icon v-else>mdi-plus-thick</v-icon>
                    </v-col>
                    <v-col
                      :class="
                        (open ? 'white--text' : '') + ' font-weight-medium'
                      "
                    >
                      {{ group.groupName }}
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="group.courses"
                    disable-filtering
                    disable-pagination
                    hide-default-footer
                    checkbox-color="msaBlue"
                    item-key="id"
                    show-select
                    class="available-course-list"
                  >
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
      <v-footer
        padless
        tile
        style="position: sticky; bottom: 0; z-index: 999"
        color="msaBlue"
      >
        <v-container fluid>
          <v-row>
            <v-col class="grow" align="right">
              <v-btn class="white red--text" @click="close()">cancel</v-btn>
            </v-col>
            <v-col class="shrink">
              <v-badge
                color="green"
                overlap
                :content="selected.length"
                :value="selected.length"
              >
                <v-btn
                  class="white msaBlue--text"
                  @click="confirm()"
                  :disabled="selected.length == 0"
                >
                  confirm
                </v-btn>
              </v-badge>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue';
export default {
  name: 'AttachCourses',
  components: {
    SearchBar,
  },
  computed: {
    headerStyles() {
      return (index) =>
        index == this.expanded
          ? {}
          : {
              backgroundImage:
                'linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%)',
            };
    },
    headerClass() {
      return (index) => (index == this.expanded ? 'msaBlue white--text' : '');
    },
  },
  data() {
    return {
      dialog: false,
      items: [],
      itemsCount: 0,
      search: '',
      headers: [
        {
          text: 'Name',
          value: 'name',
          align: 'start',
          class: 'lightGrey',
        },
      ],
      selected: [],
      expanded: null,
    };
  },
  methods: {
    getItems() {
      const params = {
        search: this.search,
        loaderText: 'Loading...',
        labelId: this.$store.getters.selectedLabel.id,
      };

      this.$axios
        .post('get-available-company-courses-for-label?format=json', params)
        .then((response) => {
          this.items = response.data;
          this.expanded = null;
        })
        .catch((error) => error);
    },
    onSearch(event) {
      this.search = event;
      this.getItems();
    },
    openDialog() {
      this.resetFields();
      this.getItems();
    },
    resetFields() {
      this.selected = [];
      this.search = '';
      this.items = [];
    },
    close() {
      this.dialog = false;
    },
    confirm() {
      const params = {
        courseTemplateIds: this.selected.map((d) => d.id),
        loaderText: 'Loading...',
        labelId: this.$store.getters.selectedLabel.id,
      };

      this.$axios
        .post('attach-courses-to-label?format=json', params)
        .then(() => {
          this.$emit('refresh');
          this.close();
        })
        .catch((error) => error);
    },
  },
};
</script>

<style>
.available-course-list .v-data-table-header th {
  background-color: #f0f0f0 !important;
  border-bottom: none !important;
}
</style>
